<template>
    <content-wrapper
        :title="$trans('Language')"
        :comback="{ name: 'settings' }"
    >
        <template #actions>
            <v-btn
                color="success"
                outlined
                @click="onSaveSettings"
            >
                {{ $trans('Save') }}
            </v-btn>
        </template>

        <settings-form
            :item="itemOfSystemLang"
            :fields="fieldsSystemLanguage"
            @change:locale="onChangeLanguage"
            class="mb-4"
        />
        <settings-form
            title="Available languages"
            hint="hint.system.enabledLocales"
            :item="itemOfEnabledLang"
            :fields="fieldsLanguages"
            v-on="checkLanguagesListeners"
        />
    </content-wrapper>
</template>

<script>
import { TRANSLATE } from '@/widgets/components/WIcon/icons'
import SettingsForm from '@apps/settings/components/SettingsForm'
import ContentWrapper from '@/components/ContentWrapper'
import { setSystemSettings } from '@apps/system/api'

export default {
    name: 'SystemLanguage',
    components: { ContentWrapper, SettingsForm },
    computed: {
        locale() {
            return this.$store.getters['system/locale']
        },
        enabledLocales() {
            return this.$store.getters['system/enabledLocales']
        },
        itemOfSystemLang() {
            return {
                locale: this.locale
            }
        },
        itemOfEnabledLang() {
            const formData = {}

            this.enabledLocales.forEach(o => {
                formData[o] = true
            })

            return formData
        },
        systemLanguages() {
            return this.$store.getters['system/languages']
        },
        appLanguages() {
            return this.$store.getters.languages
        },
        fieldsSystemLanguage() {
            const items = []

            this.appLanguages.forEach(o => {
                items.push({
                    text: this.$trans(o.locale),
                    value: o.locale
                })
            })

            return [
                {
                    icon: TRANSLATE,
                    label: 'Language of UI',
                    type: 'v-select',
                    name: 'locale',
                    props: {
                        outlined: true,
                        hideDetails: true,
                        dense: true,
                        items: items
                    }
                }
            ]
        },
        fieldsLanguages() {
            const fields = []

            this.systemLanguages.forEach(o => {
                fields.push({
                    label: o.title,
                    hint: this.$trans(o.locale),
                    type: 'v-checkbox',
                    name: o.locale,
                    props: {
                        outlined: true,
                        hideDetails: true,
                        dense: true,
                        disabled: !!o.reserved,
                    }
                })
            })

            return fields
        },
        checkLanguagesListeners() {
            const vOn = {}

            this.systemLanguages.forEach(o => {
                vOn[`change:${o.locale}`] = this.onCheckedLanguage
            })

            return vOn
        }
    },
    data() {
        return {
            settings: {}
        }
    },
    methods: {
        onCheckedLanguage(value, formData) {
            const checkedLangs = []

            for (const formDataKey in formData) {
                if (formData[formDataKey]) {
                    checkedLangs.push(formDataKey)
                }
            }

            this.settings.enabledLocales = [].concat(checkedLangs)
        },
        onChangeLanguage(value) {
            this.settings.locale = value
        },
        async onSaveSettings() {
            this.$lprogress.begin()

            try {
                await setSystemSettings(this.settings)

                await this.$store.dispatch('system/fetchSettings')
            } catch (error) {}

            this.$lprogress.end()
        }
    }
}
</script>

<style lang=scss>

</style>
